import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import Img, { FluidObject } from 'gatsby-image';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { ReactComponent as Ellipse } from '../assets/Ellipse.svg';
import { BtnLinkScrollPrimary, Heading } from './scaffolds';

interface Banner {
  fluid?: FluidObject;
  title?: string;
  link?: string;
  description?: string;
  type?: string;
  hash?: string;
  active?: boolean;
  buttonText?: string;
  buttonHref?: string;
}

interface BannerDetail {
  title?: string;
  description?: string;
  hash?: string;
  active?: boolean;
  buttonText?: string;
  buttonHref?: string;
}
interface SliderJumbotronProps {
  banners?: Banner[];
  homeImage?: FluidObject;
  pageTitle?: string;
  pageSubtitle?: any;
  bannersDetail?: BannerDetail[];
  hashtag?: string;
  jumbotronButtonText?: string;
}

const SliderJumbotron: React.FC<SliderJumbotronProps> = ({
  banners,
  homeImage,
  pageTitle,
  pageSubtitle,
  bannersDetail,
  hashtag,
  jumbotronButtonText,
}) => {
  let bannersImage: Banner[] = [
    { type: 'jumbotron', fluid: homeImage, title: pageTitle },
    ...(banners || [])
      ?.map((b, idx) => {
        return {
          ...b,
          type: 'promo',
          title: bannersDetail[idx]?.title || b.title,
          link: b.description,
          description: bannersDetail[idx]?.description || b.description,
          hash: bannersDetail[idx]?.hash || '',
          active: bannersDetail[idx]?.active || false,
          buttonText: bannersDetail[idx]?.buttonText,
          buttonHref: bannersDetail[idx]?.buttonHref,
        };
      })
      .filter(b => (!hashtag ? b.active : b)),
  ];

  const [loading, setLoading] = useState(true);

  if (hashtag) {
    // index = bannersImage.findIndex(b => hashtag.includes(b.hash)) || 0;
    bannersImage = bannersImage.filter(b => hashtag.includes(b.hash)) || [bannersImage[0]];
  }

  let timeout!: NodeJS.Timeout;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => autoSlide(), [currentIndex]);

  function left() {
    const idx = currentIndex || 0;
    if (idx < 1) {
      setCurrentIndex(bannersImage?.length - 1);
      return;
    }
    setCurrentIndex(idx - 1);
  }

  function right() {
    const idx = currentIndex || 0;
    if (idx > bannersImage?.length - 2) {
      setCurrentIndex(0);
      return;
    }
    setCurrentIndex(idx + 1);
  }

  function autoSlide() {
    setLoading(false);
    if (!hashtag) {
      timeout = setTimeout(() => {
        if (currentIndex === bannersImage.length - 1) {
          setCurrentIndex(0);
        } else {
          setCurrentIndex(currentIndex + 1);
        }
      }, 10000);
      return () => clearTimeout(timeout);
    }
  }

  const totalData = bannersImage?.length || 0;
  const activeItem = bannersImage[currentIndex];

  return (
    <section
      css={css`
        position: relative;
      `}
    >
      <div
        css={css`
          ${tw`bg-white dark:bg-black-200`}
          overflow: hidden;
          height: 659px;
          top: 0px;
          position: relative;
          @media (max-width: 1024px) {
            height: 488px;
            top: 0px;
          }
        `}
      >
        <div
          tw="bg-black-100"
          css={css`
            top: -421px;
            position: relative;
            width: calc(100% + 1560px);
            height: 1080px;
            overflow: hidden;
            border-radius: 100%;
            left: -780px;
            @media (max-width: 1024px) {
              top: -48px;
              left: -195px;
              width: calc(100% + 390px);
              height: 536px;
            }
          `}
        >
          <SwitchTransition mode="out-in">
            <CSSTransition<undefined>
              key={currentIndex}
              classNames="slide"
              addEndListener={(node: HTMLElement, done: () => void) => {
                node.addEventListener('transitionend', done, false);
              }}
            >
              {activeItem?.type === 'jumbotron' ? (
                <div
                  key={`${currentIndex}-promo`}
                  tw="flex justify-center"
                  css={css`
                    position: absolute;
                    left: 780px;
                    width: calc(100% - 1560px);
                    bottom: 0;
                    top: 421px;
                    @media (max-width: 1024px) {
                      left: 195px;
                      top: 115px;
                      width: calc(100% - 390px);
                    }
                  `}
                >
                  <Img
                    alt={pageTitle}
                    fluid={activeItem?.fluid as FluidObject}
                    css={css`
                      height: 100%;
                      width: 100%;
                    `}
                  />
                  <div
                    tw="w-full h-full flex items-center"
                    css={css`
                      background-color: rgba(39, 40, 46, 0.64);
                      top: 0;
                      position: absolute;
                    `}
                  >
                    <div tw="container">
                      <div tw="lg:w-1/3 mx-8 lg:mx-32">
                        <div tw="font-gilroy text-h2 lg:text-h1 text-white">{pageTitle}</div>
                        <div tw="text-b1 text-white mb-6">{pageSubtitle}</div>
                        <BtnLinkScrollPrimary className="gtm-all-service" to="/#layanan-dan-harga">
                          {jumbotronButtonText || 'Lihat Layanan Kami'}
                        </BtnLinkScrollPrimary>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  key={`${currentIndex}-promo`}
                  tw="flex justify-center"
                  css={css`
                    position: absolute;
                    left: 780px;
                    width: calc(100% - 1560px);
                    bottom: 0;
                    top: 421px;
                    @media (max-width: 1024px) {
                      left: 195px;
                      top: 115px;
                      width: calc(100% - 390px);
                    }
                  `}
                >
                  <Img
                    alt={pageTitle}
                    fluid={activeItem?.fluid as FluidObject}
                    css={css`
                      height: 100%;
                      width: 100%;
                    `}
                  />
                  <div
                    tw="w-full h-full flex items-center"
                    css={css`
                      background-color: rgba(39, 40, 46, 0.64);
                      top: 0;
                      position: absolute;
                    `}
                  >
                    <div tw="container">
                      <div tw="lg:w-1/3 mx-8 lg:mx-32">
                        <div tw="font-gilroy text-h2 lg:text-h1 text-white">
                          {activeItem?.title}
                        </div>
                        <div
                          tw="text-b1 text-white mb-6"
                          dangerouslySetInnerHTML={{
                            __html: activeItem?.description,
                          }}
                        ></div>
                        <BtnLinkScrollPrimary className="gtm-all-service" to="/#layanan-dan-harga">
                          {activeItem?.buttonText || jumbotronButtonText || 'Lihat Layanan Kami'}
                        </BtnLinkScrollPrimary>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
      <div
        tw="h-full"
        css={css`
          ${banners ? tw`block` : tw`hidden`}
        `}
      >
        <div
          tw="absolute left-0 lg:left-0 h-full top-0 hidden flex-col justify-center"
          css={css`
            z-index: 11;
            ${!loading && !hashtag ? tw`flex` : tw`hidden`}
          `}
        >
          <button
            tw="cursor-pointer bg-transparent border-0 p-1 lg:p-2 focus:outline-none"
            onClick={() => left()}
          >
            <span
              tw="text-gray text-h4 lg:text-h1"
              className="icon icon-chevron-left"
              aria-label="slide-left"
            ></span>
          </button>
        </div>
        <div
          tw="absolute right-0 lg:right-0 h-full top-0 hidden flex-col justify-center"
          css={css`
            z-index: 11;
            ${!loading && !hashtag ? tw`flex` : tw`hidden`}
          `}
        >
          <button
            tw="cursor-pointer bg-transparent border-0 p-1 lg:p-2 focus:outline-none"
            onClick={() => right()}
          >
            <span
              tw="text-gray text-h4 lg:text-h1"
              className="icon icon-chevron-right"
              aria-label="slide-right"
            ></span>
          </button>
        </div>
      </div>
      {/* Banner title for SEO */}
      {bannersImage?.map((b, i) => (
        <Heading key={`bannersTitle${i}`} level={i + 1} tw="hidden">
          {b.title}
        </Heading>
      ))}
      <div tw="w-full mt-8 absolute bottom[1rem] lg:bottom[1.5rem] left-0">
        <div
          tw="flex justify-center"
          css={css`
            ${banners ? tw`flex` : tw`hidden`}
          `}
        >
          {!loading && !hashtag
            ? [...Array(totalData)].map((_, i) => (
                <Ellipse
                  key={i}
                  width="10px"
                  height="10px"
                  fill={i === (currentIndex || 0) ? '#2B50CB' : '#DADADA'}
                  tw="cursor-pointer mx-1"
                  onClick={() => {
                    setCurrentIndex(i);
                    clearTimeout(timeout);
                  }}
                ></Ellipse>
              ))
            : null}
        </div>
      </div>
    </section>
  );
};

export default SliderJumbotron;
